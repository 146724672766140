import { IModalDialog } from '@models/components/modal.model';
import Image from 'next/image';

interface IModalDialogContent {
  modal?: IModalDialog;
}

const ModalDialogContent = ({ modal }: IModalDialogContent) => {
  if (modal?.children) return modal.children;

  return (
    <>
      <div className="space-y-4">
        {modal?.icon && (
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center w-[80px] h-[80px] overflow-hidden rounded-full bg--gray-200">
              <Image src={`/images/icons/${modal?.icon}.svg`} alt="Icon" width={40} height={40} />
            </div>
          </div>
        )}
        <div>
          <h4 className="h4 font-medium text-center">{modal?.title}</h4>
          <p className="clr-gray-500 text-center">{modal?.message}</p>
        </div>
      </div>
    </>
  );
};

export default ModalDialogContent;
